import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Login from './components/Login';
import Panel from './components/Panel';
import {
  Container,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from 'axios';

interface User {
  username: string;
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196f3',
      light: '#4dabf5',
      dark: '#1769aa',
    },
    secondary: {
      main: '#f50057',
      light: '#f73378',
      dark: '#ab003c',
    },
    background: {
      default: '#0a1929',
      paper: '#1a2027',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: '#1a2027',
          borderRadius: 12,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a2027',
          backgroundImage: 'none',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
  },
});

function App() {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<{type: 'success' | 'error' | ''; message: string}>({
    type: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Test endpoint'ini de kontrol edelim
    const testBackend = async () => {
      try {
        const response = await axios.get('https://pharus.abbaslaryapi.com:5000/test');
        console.log('Backend test yanıtı:', response.data);
      } catch (error) {
        console.error('Backend test hatası:', error);
      }
    };

    testBackend();
    // Kullanıcıları yükle
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      console.log('Kullanıcılar yükleniyor...');
      const response = await axios.get('https://pharus.abbaslaryapi.com:5000/api/users');
      console.log('API yanıtı:', response.data);
      if (response.data && Array.isArray(response.data)) {
        console.log('Kullanıcılar başarıyla yüklendi:', response.data);
        setUsers(response.data);
      } else {
        console.error('Geçersiz veri formatı:', response.data);
        throw new Error('Invalid response format');
      }
    } catch (error: any) {
      console.error('Kullanıcılar yüklenirken hata detayı:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setStatus({
        type: 'error',
        message: 'Kullanıcılar yüklenirken hata oluştu. Backend çalışıyor mu?'
      });
    }
  };

  const handleSubmit = async () => {
    if (!selectedUser || !message) {
      setStatus({
        type: 'error',
        message: 'Lütfen kullanıcı ve mesaj seçin'
      });
      return;
    }

    setLoading(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await axios.post('https://pharus.abbaslaryapi.com:5000/api/send-message', {
        username: selectedUser,
        message: message,
      });

      setStatus({
        type: 'success',
        message: 'Mesaj başarıyla gönderildi'
      });
      setMessage('');
    } catch (error: any) {
      setStatus({
        type: 'error',
        message: error.response?.data?.error || 'Bir hata oluştu'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/panel" element={<Panel />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
